<template>
  <footer class="footer">
    <div class="container">
      <div class="copyright">
        <md-icon>spa</md-icon><span class="aligned-icon">05052023</span>
      </div>
    </div>
  </footer>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
.container {
  justify-content: flex-end;
}
.aligned-icon {
  position: relative;
  top: 3px;
  margin-left: 3px;
}
footer {
  padding: 0;
}
</style>
