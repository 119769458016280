<template>
  <div class="user">
    <div class="photo">
      <img src="@/assets/img/avatar4.png" alt="avatar" />
    </div>
    <div class="user-info">
      <a
        data-toggle="collapse"
        :aria-expanded="!isClosed"
        @click.stop="toggleMenu"
        @click.capture="clicked"
      >
        <span>
          {{ user }}
          <b class="caret"></b>
        </span>
      </a>
      <collapse-transition>
        <div v-show="!isClosed">
          <ul class="nav">
            <slot>
              <li>
                <a v-on:click="logout">
                  <span class="sidebar-mini">LO</span>
                  <span class="sidebar-normal">Log Out</span>
                </a>
              </li>
            </slot>
          </ul>
        </div>
      </collapse-transition>
    </div>
  </div>
</template>
<script>
import { CollapseTransition } from "vue2-transitions";
export default {
  components: {
    CollapseTransition,
  },
  computed: {
    user: function() {
      return this.$store.state.user.name;
    },
  },
  data() {
    return {
      isClosed: true,
    };
  },
  methods: {
    clicked: function(e) {
      e.preventDefault();
    },
    toggleMenu: function() {
      this.isClosed = !this.isClosed;
    },
    logout: function() {
      this.$router.push({ name: "logout" });
    },
  },
};
</script>
